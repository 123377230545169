import { isBoolean } from 'lodash';

const applicantContent = {
  name: 'Applicants',
  code: 3,
  key: 'isApplicants',
  children: [
    {
      name: 'Primary Applicant',
      activeContent: true,
      childs: [
        { name: 'Details', subCode: 1 },
        { name: 'Employment', subCode: 2 },
        { name: 'Assets and Liabilities', subCode: 3 }
      ]
    }
  ]
};

const gurantorsContent = {
  name: 'Guarantors',
  code: 4,
  key: 'isGurantors',
  children: [
    {
      name: 'Guarantor 1',
      activeContent: true,
      childs: [
        { name: 'Details', subCode: 1 },
        { name: 'Employment', subCode: 2 },
        { name: 'Assets and Liabilities', subCode: 3 }
      ]
    }
  ]
};

const opportunityContents = [
  { name: 'Property Information', code: 1, key: 'isPropertyInfo' },
  { name: 'Mortgage', code: 2, key: 'isMortgage' },
  { ...applicantContent },
  { ...gurantorsContent },
  { name: 'Publish', code: 5, key: 'isPublish' }
];

const applicantChildren = {
  name: 'Primary Applicant',
  code: 3,
  key: 'isApplicants',
  childs: [
    { name: 'Details', subCode: 1 },
    { name: 'Employment', subCode: 2 },
    { name: 'Assets and Liabilities', subCode: 3 }
  ]
};

const gurantorChildren = {
  name: 'Guarantor 1',
  code: 4,
  key: 'isGurantors',
  childs: [
    { name: 'Details', subCode: 1 },
    { name: 'Employment', subCode: 2 },
    { name: 'Assets and Liabilities', subCode: 3 }
  ]
};

const setOpportunityId = (id) => {
  localStorage.setItem('opportunityId', id);
};

const getOpportunityId = (id) => {
  return localStorage.getItem('opportunityId');
};

const removeOpportunityId = () => localStorage.removeItem('opportunityId');

const setApplicantId = (id) => {
  localStorage.setItem('applicantGurantors', id);
};

const getApplicantId = () => {
  return localStorage.getItem('applicantGurantors');
};

const removeApplicantId = () => localStorage.removeItem('applicantGurantors');

const ASSET_KEY = 'asset';
const LIABILITY_KEY = 'liability';
const OTHER_INCOME_KEY = 'income';

const getSubItems = (code, subCode) => {
  switch (subCode) {
    case 1:
      return code === 3 ? 'Applicants > Details' : 'Guarantors > Details';
    case 2:
      return code === 3 ? 'Applicants > Employment' : 'Guarantors > Employment';
    case 3:
      return code === 3
        ? 'Applicants > Assets and liabilities'
        : 'Guarantors > Assets and liabilities';
    default:
      break;
  }
};

const getActiveContent = (open, code, subCode) => {
  if (open) {
    return 'Contents';
  }
  switch (code) {
    case 0:
      return 'Import from Filogix';
    case 1:
      return 'Property Information';
    case 2:
      return 'Mortgage';
    case 3:
      if (subCode === 0) {
        return 'Applicants';
      } else {
        return getSubItems(3, subCode);
      }

    case 4:
      if (subCode === 0) {
        return 'Gurantors';
      } else {
        return getSubItems(4, subCode);
      }

    case 5:
      return 'Publish';
    default:
      break;
  }
};

const checkForApplicantsValues = (data) => {
  const { isAssetsLiabilitiesAdded, isEmploymentAdded } = data || {};

  if (!isAssetsLiabilitiesAdded || !isEmploymentAdded) {
    return true;
  }

  for (const key in data) {
    if (Array.isArray(data[key])) {
      if (data[key].some(Array.isArray)) {
        if (data[key].some((arr) => arr.length > 0)) {
          return true;
        }
      } else if (data[key].length > 0) {
        return true;
      }
    }
  }

  return false;
};

const hasNonEmptyKeysApplicants = (data) => {
  return data.some((applicant) => {
    // Check if applicantDetailsKeys is not empty
    if (applicant.applicantDetailsKeys.length > 0) return true;

    // Check if any subarray in incomeKeys is not empty
    if (applicant.incomeKeys.some((subArray) => subArray.length > 0)) return true;

    // Check if assetsKeys is not empty
    if (applicant.assetsKeys.some((subArray) => subArray.length > 0)) return true;

    // Check if liabilitiesKeys is not empty
    if (applicant.liabilitiesKeys.some((subArray) => subArray.length > 0)) return true;

    // Check if any subarray in currentEmploymentKeys is not empty
    if (applicant.currentEmploymentKeys.some((subArray) => subArray.length > 0)) return true;

    // Check if any subarray in previousEmploymentKeys is not empty
    if (applicant.previousEmploymentKeys.some((subArray) => subArray.length > 0)) return true;

    // If none of the keys are non-empty, return false for this applicant
    return false;
  });
};

const isArrayEmpty = (arr) => {
  return (
    arr.length === 0 || arr.every((item) => (Array.isArray(item) ? isArrayEmpty(item) : false))
  );
};

const isAllArraysEmpty = (obj, type, applicantData) => {
  // Helper function to check if an array is empty or contains only empty arrays

  if (type === 'applicant') {
    let otherIncomeCondition = isArrayEmpty(obj.incomeKeys);
    let assetsCondition = isArrayEmpty(obj.assetsKeys);
    let liabilitiesCondition = isArrayEmpty(obj.liabilitiesKeys);
    let currentEmpCondition = isArrayEmpty(obj.currentEmploymentKeys);
    let preEmpCondition = isArrayEmpty(obj.previousEmploymentKeys);

    if (applicantData) {
      if (!applicantData.isCurrentlyEmployed) {
        applicantData.isPreviouslyEmployed = false;
        currentEmpCondition = true;
      }

      if (!applicantData.isPreviouslyEmployed) {
        preEmpCondition = true;
      }

      if (!applicantData.isOtherSourceIncome) {
        otherIncomeCondition = true;
      }

      if (!applicantData.isHavingAssets) {
        assetsCondition = true;
      }

      if (!applicantData.isHavingLiabilities) {
        liabilitiesCondition = true;
      }
    }

    return !(
      otherIncomeCondition &&
      assetsCondition &&
      liabilitiesCondition &&
      currentEmpCondition &&
      preEmpCondition
    );
  }
  // Check all relevant keys
  return !(
    isArrayEmpty(obj.applicantDetailsKeys) &&
    isArrayEmpty(obj.incomeKeys) &&
    isArrayEmpty(obj.assetsKeys) &&
    isArrayEmpty(obj.liabilitiesKeys) &&
    isArrayEmpty(obj.currentEmploymentKeys) &&
    isArrayEmpty(obj.previousEmploymentKeys)
  );
};

const applicantErrorCard = (obj, applicantData) => {
  let otherIncomeCondition = isArrayEmpty(obj.incomeKeys);
  let assetsCondition = isArrayEmpty(obj.assetsKeys);
  let liabilitiesCondition = isArrayEmpty(obj.liabilitiesKeys);
  let currentEmpCondition = isArrayEmpty(obj.currentEmploymentKeys);
  let preEmpCondition = isArrayEmpty(obj.previousEmploymentKeys);

  if (applicantData) {
    if (!applicantData.isPreviouslyEmployed) {
      preEmpCondition = true;
    }

    if (!applicantData.isCurrentlyEmployed) {
      applicantData.isPreviouslyEmployed = false;
      currentEmpCondition = true;
    }

    if (!applicantData.isOtherSourceIncome) {
      otherIncomeCondition = true;
    }

    if (!applicantData.isHavingAssets) {
      assetsCondition = true;
    }

    if (!applicantData.isHavingLiabilities) {
      liabilitiesCondition = true;
    }
  }

  if (!currentEmpCondition) {
    return 'current-employment-card';
  }

  if (!preEmpCondition) {
    return 'previous-employment-card';
  }

  if (!otherIncomeCondition) {
    return 'other-income-card';
  }

  if (!assetsCondition) {
    return 'assets-card';
  }

  if (!liabilitiesCondition) {
    return 'liabilities-card';
  }

  return null;
};

const isCardFieldsCompleted = (incompleteFields, applicantId, cardType, cardStatus) => {
  if (isBoolean(cardStatus) && !cardStatus) {
    return false;
  }

  const { missingApplicantGurantorFields } = incompleteFields;

  const index = missingApplicantGurantorFields.findIndex((val) => {
    return val.id === applicantId;
  });
  if (index > -1) {
    const previousEmpFields = missingApplicantGurantorFields[index][cardType];
    const checkFields = previousEmpFields.some((innerArray) => innerArray.length > 0);
    return checkFields;
  }
};

export {
  applicantContent,
  gurantorsContent,
  opportunityContents,
  setOpportunityId,
  getOpportunityId,
  setApplicantId,
  getApplicantId,
  applicantChildren,
  gurantorChildren,
  removeOpportunityId,
  removeApplicantId,
  ASSET_KEY,
  LIABILITY_KEY,
  OTHER_INCOME_KEY,
  getActiveContent,
  checkForApplicantsValues,
  hasNonEmptyKeysApplicants,
  isAllArraysEmpty,
  isCardFieldsCompleted,
  applicantErrorCard,
  isArrayEmpty
};
