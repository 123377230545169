import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

const ComingSoon = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <Helmet>
        <title>Page not found | Openfund</title>
      </Helmet>
      <div className="text-center">
        <h1>Page not found</h1>
        <Button color="link" className="py-0" onClick={() => navigate('/dashboard')}>
          Go to dashboard
        </Button>
      </div>
    </div>
  );
};

export default ComingSoon;
