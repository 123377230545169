import { gql } from '@apollo/client';

const GET_DIRECTORS = gql`
  query getDirectorDetails($userId: Int!, $accountTypeId: Int, $investorAccountId: Int) {
    getDirectorDetails(
      userId: $userId
      accountTypeId: $accountTypeId
      investorAccountId: $investorAccountId
    )
  }
`;

export { GET_DIRECTORS };
