import { gql } from '@apollo/client';

const PERSONAL_DETAILS = gql`
  mutation AddPersonalDetail($userInput: UserInput!) {
    addPersonalDetail(userInput: $userInput)
  }
`;

const VERIFY_OTP = gql`
  mutation VerifyOtp($email: String!, $otp: Int!) {
    verifyOtp(email: $email, otp: $otp)
  }
`;

const TWO_FACTOR_OTP_VERIFY = gql`
  mutation MobileOtpVerification($email: String!, $otp: Int!) {
    mobileOtpVerification(email: $email, otp: $otp)
  }
`;

const RESEND_OTP = gql`
  mutation ResendOtp($email: String!, $type: String!) {
    resendOtp(email: $email, type: $type)
  }
`;

const SEND_VERIFICATION_OTP = gql`
  mutation sendVerificationOtp($type: String!, $email: String, $phoneNumber: String) {
    sendVerificationOtp(type: $type, email: $email, phoneNumber: $phoneNumber)
  }
`;

const VERIFY_EMAIL_PHONENUMBER_OTP = gql`
  mutation VerifyEmailOrPhoneNumberOTP(
    $userId: Int!
    $type: String!
    $otp: String!
    $value: String!
  ) {
    verifyEmailOrPhoneNumberOTP(userId: $userId, type: $type, otp: $otp, value: $value)
  }
`;

export {
  PERSONAL_DETAILS,
  VERIFY_OTP,
  TWO_FACTOR_OTP_VERIFY,
  RESEND_OTP,
  SEND_VERIFICATION_OTP,
  VERIFY_EMAIL_PHONENUMBER_OTP
};
