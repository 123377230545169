import { gql } from '@apollo/client';

const GET_BENEFICIARIES = gql`
  query getBeneficiary($userId: Int!, $accountTypeId: Int, $investorAccountId: Int) {
    getBeneficiary(
      userId: $userId
      accountTypeId: $accountTypeId
      investorAccountId: $investorAccountId
    ) {
      beneficiarys {
        id
        beneficiaryLastName
        beneficiaryLegalName
        beneficiaryPhoneNo
        beneficiaryRelationAccount
        investorAccountId
        isApproved
      }
      count
    }
  }
`;

const GET_BENEFICIARY_APPROVAL_DATA = gql`
  query getBeneficiaryApprovalData($accountId: Int!) {
    getBeneficiaryApprovalData(accountId: $accountId)
  }
`;

const GET_DIRECTOR_APPROVAL_DATA = gql`
  query getDirectorApprovalData($accountId: Int!) {
    getDirectorApprovalData(accountId: $accountId)
  }
`;

const GET_JOINT_MEMBER_APPROVAL_DATA = gql`
  query getJointMemberApprovalData($accountId: Int!) {
    getJointMemberApprovalData(accountId: $accountId)
  }
`;

const GET_JOINT_MEMBERS = gql`
  query getJointMembers($userId: Int!, $accountTypeId: Int, $investorAccountId: Int) {
    getJointMembers(
      userId: $userId
      accountTypeId: $accountTypeId
      investorAccountId: $investorAccountId
    ) {
      jointMembers {
        id
        jointLegalName
        jointLastName
        jointRelationAccount
        jointPhoneNo
        investorAccountId
        isApproved
      }
      count
    }
  }
`;

const GET_TRUST_MEMBER_APPROVAL_DATA = gql`
  query getTrustMemberApprovalData($accountId: Int!) {
    getTrustMemberApprovalData(accountId: $accountId)
  }
`;

const GET_TRUST_MEMBERS = gql`
  query getTrustMembers($userId: Int!, $accountTypeId: Int, $investorAccountId: Int) {
    getTrustMembers(
      userId: $userId
      accountTypeId: $accountTypeId
      investorAccountId: $investorAccountId
    ) {
      trustMembers {
        id
        trustLegalName
        trustLastName
        trustRelationAccount
        trustPhoneNo
        investorAccountId
        isApproved
      }
      count
    }
  }
`;

export {
  GET_BENEFICIARIES,
  GET_BENEFICIARY_APPROVAL_DATA,
  GET_DIRECTOR_APPROVAL_DATA,
  GET_JOINT_MEMBERS,
  GET_JOINT_MEMBER_APPROVAL_DATA,
  GET_TRUST_MEMBERS,
  GET_TRUST_MEMBER_APPROVAL_DATA
};
