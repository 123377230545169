import React, { createContext, useState, useMemo } from 'react';
import {
  opportunityContents,
  applicantChildren,
  gurantorChildren,
  applicantContent,
  gurantorsContent
} from '../Helper';

export const OpportunityContentsContext = createContext('');

const OpportunityContentsProvider = ({ children }) => {
  const [contents, setContents] = useState(opportunityContents);
  const [requestSummary, setRequestSummary] = useState({
    position: '',
    amount: '',
    rate: '',
    LTV: '',
    GDS: '',
    TDS: '',
    term: '',
    type: '',
    payment: '',
    existingMortgages: []
  });

  const [applicantGurantorSummary, setApplicantGurantorSummary] = useState({
    totalIncome: null,
    totalDebts: null,
    GDS: null,
    TDS: null
  });

  const updateContents = (name, indexKey, id) => {
    let copyContents = [...contents];
    copyContents[indexKey].children[copyContents[indexKey].children.length - 1].name = name;
    if (id) {
      copyContents[indexKey].children[copyContents[indexKey].children.length - 1].id = id;
    }
    setContents(copyContents);
  };

  const onResetContents = (id) => {
    const newContent = [...opportunityContents];
    const newApp = { ...applicantContent };
    newApp.children[0].name = 'Primary Applicant';
    newApp.children[0].key = 'subApplicant';
    newApp.children[0].id = id;
    const newGurantor = { ...gurantorsContent };
    newGurantor.children[0].name = 'Guarantor 1';
    newContent[2] = { ...newApp };
    newContent[3] = { ...newGurantor };
    setContents(newContent);
  };

  const getGurantor = (data, randomId) => {
    let copyGurantor = { ...gurantorChildren, activeContent: true, id: randomId };
    copyGurantor.name = `Guarantor ${data.length + 1}`;
    return { ...copyGurantor };
  };

  const getApplicants = (data, randomId) => {
    const copy = { ...applicantChildren, activeContent: true, id: randomId, key: 'subApplicant' };
    copy.name = `Applicant ${data.length + 1}`;
    return { ...copy };
  };

  const onEditContents = (index, data, randomId) => {
    let copyContents = [...contents];
    let applicantGurantorData = data.map((item) => {
      let copy = index === 2 ? { ...applicantChildren } : { ...gurantorChildren };
      copy.name = `${item.legalFirstName} ${item.legalLastName}`;
      copy.activeContent = false;
      copy.id = item.id;
      return { ...copy };
    });
    copyContents[index].children = [
      ...applicantGurantorData,
      index === 2 ? getApplicants(data, randomId) : getGurantor(data, randomId)
    ];
    setContents(copyContents);
  };

  const onSetApplicantGurantor = (index, data, id) => {
    let copyContents = [...contents];
    if (data && data.length) {
      let applicantGurantorData = data.map((item) => {
        let copy = index === 2 ? { ...applicantChildren } : { ...gurantorChildren };
        copy.name = `${item.legalFirstName} ${item.legalLastName}`;
        copy.activeContent = item.activeContent;
        copy.id = item.id;
        return { ...copy };
      });
      copyContents[index].children = [...applicantGurantorData];
      setContents(copyContents);
    } else {
      onResetContents(id);
    }
  };

  const onUpdateRequestSummary = (type, value) => {
    setRequestSummary((pre) => ({
      ...pre,
      [type]: value
    }));
  };

  const onUpdateApplicantGurantorSummary = (obj) => {
    setApplicantGurantorSummary((pre) => ({
      ...pre,
      ...obj
    }));
  };

  let context = useMemo(
    () => ({
      contents,
      setContents,
      updateContents,
      onEditContents,
      onSetApplicantGurantor,
      requestSummary,
      onUpdateRequestSummary,
      applicantGurantorSummary,
      onUpdateApplicantGurantorSummary,
      onResetContents
    }),
    [contents, requestSummary, applicantGurantorSummary, onResetContents]
  );

  return (
    <OpportunityContentsContext.Provider value={context}>
      {children}
    </OpportunityContentsContext.Provider>
  );
};

export default OpportunityContentsProvider;
