import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

// Using custom test method
function validateNumber(message) {
  return this.test('validateNumber', message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: 'Required' });
    }

    if (!isValidPhoneNumber(value)) {
      return createError({ path, message: 'Please enter a valid phone number.' });
    }

    return true;
  });
}

Yup.addMethod(Yup.string, 'validateNumber', validateNumber);

export const personalDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('Please enter your first name.')
    .max(25, 'first name should be with in 25 character'),
  lastName: Yup.string()
    .trim()
    .required('Please enter your last name.')
    .max(25, 'last name should be with in 25 character'),
  preferredName: Yup.string()
    .trim()
    .required('Please enter preferred name.')
    .max(50, 'last name should be with in 50 character'),
  phoneNumber: Yup.string().required('Please enter a phone number').validateNumber()
});

export const personalInfoCommonValidation = {
  firstName: Yup.string()
    .trim()
    .required('Please enter your first name.')
    .max(25, 'first name should be with in 25 character'),
  lastName: Yup.string()
    .trim()
    .required('Please enter your last name.')
    .max(25, 'last name should be with in 25 character'),
  preferredName: Yup.string()
    .trim()
    .required('Please enter preferred name.')
    .max(50, 'last name should be with in 50 character'),
  phoneNumber: Yup.string().required('Please enter a phone number').validateNumber(),
  email: Yup.string()
    .email('Please enter a valid email address')
    .matches(/^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, 'Please enter your email address')
    .max(70, 'Email is too long')
    .trim()
    .required('Please enter your email address')
};

export const personalDetailsInitValues = {
  firstName: '',
  lastName: '',
  preferredName: '',
  phoneNumber: ''
};

// export { personalDetailsSchema, personalDetailsInitValues };
