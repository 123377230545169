const WITHDRAW_TYPE = 'withdraw';
const ACCEPT_TYPE = 'accept';
const REJECT_TYPE = 'reject';
const IN_PROGRESS = 'inprogress';
const NEW_OFFER_TYPE = 'new';
const EDIT_OFFER_TYPE = 'edit';
const SUGGEST_OFFER_TYPE = 'suggest';
const COUNTER_OFFER_TYPE = 'counter';
const ACCEPT_CHANGES_TYPE = 'accept_changes';
const FUNDED_TYPE = 'funded';
const FUNDED_BY_BROKER = 'funded_by_broker';
const RENEW_DISCHARGE_KEY = 'renew_discharge';
const RENEW_KEY = 'renewed';
const DISCHARGE_KEY = 'discharged';
const SHARED = 'shared';

export {
  WITHDRAW_TYPE,
  ACCEPT_TYPE,
  REJECT_TYPE,
  IN_PROGRESS,
  NEW_OFFER_TYPE,
  EDIT_OFFER_TYPE,
  SUGGEST_OFFER_TYPE,
  COUNTER_OFFER_TYPE,
  ACCEPT_CHANGES_TYPE,
  FUNDED_TYPE,
  FUNDED_BY_BROKER,
  RENEW_DISCHARGE_KEY,
  RENEW_KEY,
  DISCHARGE_KEY,
  SHARED
};
