import { gql } from '@apollo/client';

const GET_RECOMMENDED_INVESTOR = gql`
  query getRecommendedMAsForInvestor(
    $userId: Int
    $offset: Int
    $limit: Int
    $sortValue: String
    $sortKey: String
    $search: String
    $requestStatus: [String]
  ) {
    getRecommendedMAsForInvestor(
      userId: $userId
      offset: $offset
      limit: $limit
      sortValue: $sortValue
      sortKey: $sortKey
      search: $search
      requestStatus: $requestStatus
    )
  }
`;

const INVITE_REQUEST_SUBSCRIPTION = gql`
  subscription inviteRequestSubscription($userId: Int!, $roleId: Int!) {
    inviteRequestSubscription(userId: $userId, roleId: $roleId)
  }
`;

export { GET_RECOMMENDED_INVESTOR, INVITE_REQUEST_SUBSCRIPTION };
