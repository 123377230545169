import { gql } from '@apollo/client';

const GET_BROKERS = gql`
  query GetBrokers(
    $offset: Int
    $limit: Int
    $sortKey: String
    $sortValue: String
    $search: String
    $filter: JSON
  ) {
    getBrokers(
      offset: $offset
      limit: $limit
      sortKey: $sortKey
      sortValue: $sortValue
      search: $search
      filter: $filter
    ) {
      count
      users {
        id
        firstName
        lastName
        email
        phoneNumber
        profilePicture
        city1
        city2
        country1
      }
    }
  }
`;

const GET_INVITATION_REQUEST_LIST = gql`
  query getInvitationsRequests(
    $invitedBy: Int
    $invitedTo: Int
    $inviteRequestType: String
    $actionType: String
    $sortKey: String
    $sortValue: String
  ) {
    getInvitationsRequests(
      invitedBy: $invitedBy
      invitedTo: $invitedTo
      inviteRequestType: $inviteRequestType
      actionType: $actionType
      sortKey: $sortKey
      sortValue: $sortValue
    ) {
      count
      invitesRequests {
        actionType
        createdAt
        email
        id
        inviteBy
        inviteRequestType
        inviteTo
        invitedBy
        invitedTo
        updatedAt
      }
    }
  }
`;

const ACCEPT_REJECT_INVITATION = gql`
  mutation AcceptRejectInvitation($invitationRequestId: Int!, $actionType: String!) {
    acceptRejectInvitation(invitationRequestId: $invitationRequestId, actionType: $actionType)
  }
`;

const GET_CONNECTIONS = gql`
  query getConnections(
    $offset: Int
    $limit: Int
    $sortKey: String
    $sortValue: String
    $parentId: Int
    $childId: Int
  ) {
    getConnections(
      offset: $offset
      limit: $limit
      sortKey: $sortKey
      sortValue: $sortValue
      parentId: $parentId
      childId: $childId
    ) {
      count
      connections {
        child
        childId
        childRoleId
        createdAt
        id
        parent
        parentId
        parentRoleId
        updatedAt
        parentBussiness
        opportunityFundedAmount
        opportunityFundedCount
        opportunityInprogressAmount
        opportunityInprogressCount
      }
    }
  }
`;

const REMOVE_AGENT_FROM_BROKERAGE = gql`
  mutation removeAgentFromBrokerage($brokerId: Int!, $connectionId: Int) {
    removeAgentFromBrokerage(brokerId: $brokerId, connectionId: $connectionId)
  }
`;

const GET_BROKERS_FROM_GOOGLE = gql`
  query getBrokersFromGoogle($googleBorkerUrl: String!) {
    getBrokersFromGoogle(googleBorkerUrl: $googleBorkerUrl)
  }
`;

const GET_PLACE_DETAIL_BY_ID = gql`
  query getPlaceDetailById($placeId: String!) {
    getPlaceDetailById(placeId: $placeId)
  }
`;

const CHECK_AVAILABLE_REQUEST = gql`
  query checkAvailableRequest($invitedBy: Int!, $invitedTo: Int!) {
    checkAvailableRequest(invitedBy: $invitedBy, invitedTo: $invitedTo)
  }
`;

export {
  GET_BROKERS,
  GET_INVITATION_REQUEST_LIST,
  ACCEPT_REJECT_INVITATION,
  GET_CONNECTIONS,
  REMOVE_AGENT_FROM_BROKERAGE,
  GET_BROKERS_FROM_GOOGLE,
  GET_PLACE_DETAIL_BY_ID,
  CHECK_AVAILABLE_REQUEST
};
